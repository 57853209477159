// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

